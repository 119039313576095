
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { VueTelInput } from "vue3-tel-input";
// import "vue3-tel-input/dist/vue3-tel-input.css";
export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
    // VueTelInput,
  },
  setup() {
    const phone = ref(null);
    const formData = ref({
      mobileno: "",
      message: "",
    });
    const contatcId = ref<any>("");
    const urlback = ref<any>("");
    const groupId = ref<any>("");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const validNumber = ref<any>();
    const phone_number = ref<string>("");
    const page = ref<string>("");
    const inputOptions = ref<any>({
      styleClasses: "form-control form-control-lg form-control-solid",
    });
    const mobilenoData = ref("");
    // const value = ref('');
    const registration = Yup.object().shape({
      mobileno: Yup.number()
        .typeError("Please Speacify a number")
        .required()
        .label("Number"),
      message: Yup.string().required().label("Message"),
    });

    onMounted(() => {
      const CId = route.params.id;
      contatcId.value = CId;
      // if (data) {
      //   // getUser(id);
      //   // page.value = "Edit";
      //   setCurrentPageBreadcrumbs("Edit User", ["User"]);
      // } else {
      //   // page.value = "Add";
      groupId.value = route.params.groupId;
      const data = router.options.history.state.back;
      urlback.value = data;
      getData();
      setCurrentPageBreadcrumbs("Add Contact Note", []);
      // }
      // setCurrentPageBreadcrumbs("Add User", ["User"]);
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        //formData.value = data
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      // const id = route.params.id;
      // if (id) {
      //   getUser(id);

      //   page.value = "Edit";
      // } else {
      //   page.value = "Add";
      // }
    });
    const getData = () => {
      var id = route.params.id;
      var request = {
        url: `contact/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          mobilenoData.value = data.data;
          // console.log(data)
        }
      });
    };

    const testFunction = (event, phoneObject) => {
      if (phoneObject !== undefined) {
        // console.log(event);
        // console.log(phoneObject);
        validNumber.value = phoneObject;
        if (phoneObject.valid) {
          phone_number.value = phoneObject.number;
        } else {
          phone_number.value = "";
        }
      }
    };
    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing errors

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      console.log("values", values);
      console.log("resetForm", resetForm);
      // var request = {
      //   url: 'profile/changePassword',
      //   data: {
      //     old_password: values.current_password,
      //     new_password: values.password,
      //     user_id: profile.value._id
      //   }
      // }
      // Dummy delay
      // setTimeout(() => {
      // Send login request
      loading.value = true;
      var request = {
        url: "notes",
        data: {
          group_id: values.groupId,
          contact_id: contatcId.value,
          message: values.message,
          mobileno: values.mobileno,
        },
      };
      store
        .dispatch(Actions.POST, request)
        .then((data) => {
          if (data) {
            resetForm();
            Swal.fire({
              text: "Contact Note added successfully!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "okay",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success",
              },
            }).then(() => {
              router.push({
                path: "/user/group/view-contact/" + groupId.value,
              });
            });
            // Swal.fire("Success", "Contact Note added successfully!", "success");
            // router.push({ path: urlback });
          }
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      // }, 2000);
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      phone,
      testFunction,
      validNumber,
      phone_number,
      formData,
      inputOptions,
      page,
      getData,
      groupId,
      urlback,
      contatcId,
      mobilenoData,
    };
  },
});
